<template>
  <div class="page-not-found">
    <public-header/>
    <img class="page-not-found-img" src="@/assets/icons/notFound.svg" alt="">
    <h4 class="page-not-found-title">404 {{ $t('pageNotFound') }}</h4>
    <p class="page-not-found-text">{{ $t('resourceRequestedCouldNotFoundThisServer') }}</p>
    <router-link class="page-not-found-link" to="/"><span>{{ $t('home') }}</span></router-link>
  </div>
</template>

<script>
import PublicHeader from '@/components/PublicHeader';

export default {
  name: 'PageNotFound',
  components: {
    PublicHeader,
  },
};
</script>

<style src="./PageNotFound.scss" lang="scss"></style>
